<template>
	<div class="app-main__inner">
	  <!-- Filter Overlay -->
	  <div
		v-if="showFilter"
		class="absolute bg-red-30 inset-0"
		style="z-index: 999;"
		@click="showFilter = !showFilter"
	  ></div>
  
	  <div class="container mb-3">
		<!-- Header Section -->
		<div class="row flex flex-col md:flex-row justify-between align-items-center mb-3">
		  <div class="flex">
			<div class="flex justify-between align-items-center space-x-3 mb-3">
			  <span class="text-sm md:text-2xl dashboard-head-size font-semibold text-gray-900">
				{{ $t('customerSettings') }}
			  </span>
			</div>
			<div class="flex ml-3">
			  <span
				@click="openCreateModal"
				class="text-xs h-10 sm-center create-order-btn cursor-pointer lg:pt-1"
			  >
				<svg
				  width="20"
				  height="24"
				  viewBox="0 0 20 24"
				  fill="none"
				  xmlns="http://www.w3.org/2000/svg"
				>
				  <path
					d="M11.4735 0C11.7764 0 12.0093 0.247059 12.0093 0.541176V4.32941C12.0093 6.48235 13.7682 8.24706 15.8998 8.25882C16.7851 8.25882 17.484 8.27059 18.0198 8.27059C18.3809 8.27059 18.975 8.25882 19.4758 8.25882C19.767 8.25882 20 8.49412 20 8.78823V18.2471C20 21.1647 17.6587 23.5294 14.7583 23.5294H5.49796C2.46942 23.5294 0 21.0471 0 17.9882V5.30588C0 2.38824 2.35294 0 5.25335 0H11.4735ZM9.78451 9.14118C9.30693 9.14118 8.91089 9.52941 8.91089 10.0118V12.0471H6.9074C6.42982 12.0471 6.03378 12.4353 6.03378 12.9294C6.03378 13.4118 6.42982 13.8 6.9074 13.8H8.91089V15.8353C8.91089 16.3176 9.30693 16.7059 9.78451 16.7059C10.2621 16.7059 10.6465 16.3176 10.6465 15.8353V13.8H12.6616C13.1392 13.8 13.5352 13.4118 13.5352 12.9294C13.5352 12.4353 13.1392 12.0471 12.6616 12.0471H10.6465V10.0118C10.6465 9.52941 10.2621 9.14118 9.78451 9.14118ZM13.7054 1.06612C13.7054 0.559059 14.3146 0.307294 14.6629 0.673176C15.9221 1.99553 18.1224 4.30729 19.3525 5.59906C19.6926 5.95553 19.4433 6.54729 18.9529 6.54847C17.9955 6.552 16.8667 6.54847 16.0549 6.54023C14.7666 6.54023 13.7054 5.46847 13.7054 4.16729V1.06612Z"
					fill="#E8B105"
				  />
				</svg>
			  </span>
			</div>
		  </div>
  
		  <!-- Buttons Section -->
		  <div class="flex space-x-2">
			<!-- Customer Category Button -->
			<div class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl">
			  <span
				@click="openCustomerCategoryDrawer"
				class="text-xs h-10 flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-md bg-white text-gray-400"
			  >
				{{ $t('customerCategory') }}
			  </span>
			</div>
  
			<!-- Sync Customers Button -->
			<div
			  class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl"
			>
			  <span
				@click="handleSyncCustomers"
				class="text-xs h-10 flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-md bg-white text-gray-400"
			  >
				{{ $t('syncCustomers') }}
			  </span>
			</div>
		  </div>
		</div>
  

		<!-- Loading Indicator -->
		<div v-if="isSyncing" class="flex flex-col items-center mt-4">
			<div class="w-10/12 md:w-6/12 bg-gray-200 rounded-full h-4 relative overflow-hidden">
			  <div
				class="bg-blue-600 h-4 absolute animate-progress"
				style="width: 100%;"
			  ></div>
			</div>
			<p class="text-sm mt-2 text-gray-500">
			  {{ $t('syncingDescription') }} 
			</p>
		  </div>
	



			<div class="row flex justify-end">
				<div class="col-md-12 mt-2">
					<span v-if="GET_CUSTOMER_FILTER.length > 0"
						  class="flex block text-gray-400 justify-end items-center text-xs mb-2">
                <span class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24"
						 stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
						  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"/>
                    </svg>
                </span>
                {{ $t('activeFilter') }}
            </span>
					<div class="flex flex-wrap md:space-x-4 justify-end mb-2 pr-2">
                		<span class="flex justify-between space-x-3 font-semibold text-blue-900 text-xs"
							  v-for="(item, i) in GET_CUSTOMER_FILTER" :key="item.mode">
                    <span>
                        {{ item.mode === 'is_relevant' ? $t('relevantCustomer') : null }}
                    </span>
                    <span class="cursor-pointer" @click="removeItemFromFilter(item)">x</span>
                </span>
					</div>
				</div>
				<div class="col-md-12 flex justify-center md:justify-end mb-2 bg-white border rounded-md p-2">
					<div
						class="flex flex-col md:flex-row justify-end align-items-center space-x-4 space-y-3 md:space-y-0 w-full">
                        <span class="cursor-pointer" @click="multipleAssignRelevant"
							  v-if="GET_SHOP_CUSTOMERS_CHECKED && GET_SHOP_CUSTOMERS_CHECKED.status">
                            <span
								:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
								:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
								class="flex justify-between align-items-center space-x-4 text-white border px-3 py-2 rounded-md">
                                {{ $t('massAssign') }} {{ $t('relevantCustomer') }}
                            </span>
                        </span>
						<span class="cursor-pointer" @click="multipleAssignCategory"
							  v-if="GET_SHOP_CUSTOMERS_CHECKED && GET_SHOP_CUSTOMERS_CHECKED.status">
                            <span
								:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
								:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
								class="flex justify-between align-items-center space-x-4 text-white border px-3 py-2 rounded-md">
                                {{ $t('massAssign') }} {{ $t('category') }}
                            </span>
                        </span>
						<div>
                            <span class="mb-2 md:mb-0 cursor-pointer" @click="showFilter = !showFilter">
                                <span
									class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md">
                                    <span><img src="./../../assets/images/icons/Frame.png" alt=""></span>
                                    <span class="text-gray-500">{{ $t('filter') }}</span>
                                </span>
                            </span>
							<div class="absolute w-60 bg-white rounded border shadow-lg" style="z-index:9999"
								 v-if="showFilter">
								<FilterCustomerComponent
									@hideAdvanceFilter='showFilter = !showFilter'
									@filterRelevantCustomers='filterRelevantCustomers'/>
							</div>
						</div>
						<div class="w-full lg:w-4/12">
							<input type="text" @input="searchInputTrigger"
								   class="flex justify-between align-items-center w-full space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
						</div>
					</div>
				</div>
			</div>


			<div class="row dm-sans-font">
				<div class="w-full mt-3"> <!--pl-5 pr-5-->
					<div class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" id="exampledestroy"
							   class="table table-hover table-bordered">
							<thead>
							<tr>
								<th><input type="checkbox" name="checkboxAll" v-model="checkboxAll"
										   class="font-xs checkbox-height" @change="toggleAllCheckbox"/></th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('number')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_number')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_number')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('customerName')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_name')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_name')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('category')
										}}<span class="flex flex-col"><i @click="sortCategoryAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCategoryDsc" class="lnr-chevron-down cursor-pointer small"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">
										{{ $t('accountNumber') }}<span class="flex flex-col"><i
										@click="sortAccNumberAsc" class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortAccNumberDsc"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('costCenter') }}<span
										class="flex flex-col"><i @click="sortCostCenterAsc"
																 class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortCostCenterDsc"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">Relevant ÅF</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">
										{{ $t('totalOrderAmount') }}<span class="flex flex-col"><i
										@click="sortCustomer('total_order_amount')"
										class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortCustomer('-total_order_amount')"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">Action</th>
							</tr>
							</thead>
							<tbody v-for="(customer, i) in GET_SHOP_CUSTOMERS.data" :key="i">
							<CustomerTableSetting :customer='customer' @openRelevantModal="openRelevantModal"
												  @openCustomerEdit="openCustomerEditModal"
												  :key="`${i}_${customer.id}`"/>
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="20" selected>20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_SHOP_CUSTOMERS.data"> <!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_SHOP_CUSTOMERS.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
				</div>
				<!-- <div class="col-md-1"></div> -->
			</div>
		</div>

		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash.debounce'
import bus from './../../bus'
import Paginate from 'vuejs-paginate'
import CustomerTableSetting from './components/CustomerSetTable.vue'
import FilterCustomerComponent from './components/FilterCustomerComponent.vue'

export default {
	name: 'customer-settings',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		paginate: Paginate,
		CustomerTableSetting,
		FilterCustomerComponent
		// DownloadTableManager
	},
	data() {
		return {
			PaymentType: null,
			showFilter: false,
      		isSyncing: false,
			loading: false,
			filter: '',
			billing: {
				name: '',
				company_id: '',
				address: {
					address_line_one: '',
					zip_code: '',
					city: '',
					phone_mobile: '',
				},
				email: '',
				invoiceEmail: ''
			},
			Customers: [],
			history: [],
			copyHistory: [],
			updating: false,
			paymentCardModal: false,
			processingCard: false,
			processingInvoice: false,
			loading: true,
			checkboxAll: false,
			limit: 20,
		}
	},
	watch: {
		limit: function (newVal, oldVal) {
			this.limit = newVal
			window.localStorage.setItem('cnum', newVal)
		},
	},
	computed: {
		...mapGetters({
			GET_SHOP_CUSTOMERS: 'portal/GET_SHOP_CUSTOMERS',
			GET_SHOP_CUSTOMERS_CHECKED: 'portal/GET_SHOP_CUSTOMERS_CHECKED',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_CUSTOMER_FILTER: 'portal/GET_CUSTOMER_FILTER',
			GET_COMPANY: 'portal/GET_COMPANY_USER'
		})
	},
	mounted() {
		const num = window.localStorage.getItem('cnum')

		if (parseInt(num) && parseInt(num) < 201) {
			this.limit = num
			this.getCompanyEndCustomers(parseInt(num))
		} else {
			window.localStorage.setItem('cnum', this.limit)
			this.getCompanyEndCustomers(this.limit)
		}
		this.getActiveAccounts()
		this.getCompanyInfo()

		window.Bus.$on('get-customer-info', e => {
			if (e.removed) {
				this.checkboxAll = false
			}
			this.getCompanyEndCustomers(this.limit)
		})
		window.Bus.$on('reset-customers-settings', e => {
			this.getCompanyEndCustomers(this.limit)
		})
		window.Bus.$on('handle-customer-filter', _ => {
			this.handleCustomerFilter()
		})
	},
	methods: {
		getCompanyInfo: function () {
			this.$store.dispatch('portal/getCompanyUser')
		},
		removeItemFromFilter: function (item) {
			this.$store.commit('portal/REMOVE_CUSTOMER_FILTER', item)
			this.handleCustomerFilter()
		},
		openCategoryModal() {
			bus.$emit('open-modal')
		},
		toggleAllCheckbox(e) {
			this.$store.commit('portal/TOGGLE_ALL_CUSTOMER_CHECKBOX', this.checkboxAll)
		},
		searchInputTrigger: debounce(function (event) {
			this.filterSearch(event)
		}, 500),
		filterSearch(event) {
			// this.$store.commit('portal/FILTER_SEARCH_CUSTOMER', { value : event.target.value })
			this.filter = event.target.value
			let page = this.$services.endpoints.COMPANY_ENDCUSTOMERS + `?limit=${this.limit}`
			if (event.target.value !== '') {
				page += `&search=${event.target.value}`
			}
			this.GET_CUSTOMER_FILTER.map(item => {
				page += `&${item.mode}=${item.value}`
			})
			this.loading = true
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		handleSyncCustomers() {
			this.isSyncing = true;

			this.$store.dispatch('portal/syncCustomers')
				.then((response) => {
				this.isSyncing = false;

				// Display toast notification for success
				this.$notify({
					title: this.$t('Success'),
					message: response.data.message || this.$t('Sync completed successfully'),
					type: 'success',
				});

				// Refresh customer list
				this.getCompanyEndCustomers(this.limit);
				})
				.catch((error) => {
	
				this.isSyncing = false;

				// Display toast notification for error
				this.$notify({
					title: this.$t('Error'),
					message: error.response?.data?.message || this.$t('Failed to sync customers'),
					type: 'error',
				});
				});
			},
		changePageNumber(number) {
			this.loading = true
	
			let page = this.$services.endpoints.COMPANY_ENDCUSTOMERS + `?page=${number}&limit=${this.limit}`
			if (this.filter !== '') {
				page += `&search=${this.filter}`
			}
			this.GET_CUSTOMER_FILTER.map(item => {
				page += `&${item.mode}=${item.value}`
			})
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		filterRelevantCustomers(data) {
			this.loading = true
			this.filter = `&is_relevant=${data.name}`
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: this.$services.endpoints.COMPANY_ENDCUSTOMERS + `?limit=${this.limit + this.filter}`})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		handleCustomerFilter() {
			this.loading = true
			let page = this.$services.endpoints.COMPANY_ENDCUSTOMERS + `?limit=${this.limit}`
			this.GET_CUSTOMER_FILTER.map(item => {
				page += `&${item.mode}=${item.value}`
			})
			if (this.filter !== '') {
				page += `&search=${this.filter}`
			}
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},

		getActiveAccounts() {
			this.$store.dispatch('portal/getActiveAccounts')
		},
		getCompanyEndCustomers(limit) {
			this.loading = true
			this.$store.dispatch('portal/getCompanyEndCustomers', {limit: limit})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		openCustomerSettingDrawer() {
			return this.$store.commit('utility/OPEN_CUSTOMER_SETTING_DRAWER')
		},
		openCustomerCategoryDrawer() {
			return this.$store.commit('utility/OPENE_CUSTOMER_CATEGRY_DRAWER')
		},
		multipleAssignRelevant() {
			window.Bus.$emit('show-relevant-customer', {multiple: true})
		},
		multipleAssignCategory() {
			window.Bus.$emit('multiple-assign-category')
		},
		openRelevantModal(data) {
			window.Bus.$emit('show-relevant-customer', {data, multiple: false})
		},
		openCustomerEditModal(data) {

			window.Bus.$emit('open-customer-modal', data)
		},
		shopResultNumber(e) {
			this.limit = parseInt(e.target.value)
			this.getCompanyEndCustomers(parseInt(e.target.value))
		},
		sortNumberAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_NUMBER_ASC')
		},
		sortNumberDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_NUMBER_DESC')
		},
		sortCustomerNameAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_NAME_ASC')
		},
		sortCustomerNameDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_NAME_DESC')
		},
		sortCategoryAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_CATE_ASC')
		},
		sortCategoryDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_CATE_DESC')
		},
		sortCostCenterAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_COST_CENTER_ASC')
		},
		sortCostCenterDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_COST_CENTER_DESC')
		},
		sortAccNumberAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_ACCOUNT_NUM_ASC')
		},
		sortAccNumberDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_ACCOUNT_NUM_DESC')
		},
		sortTotalOrderAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_ORDER_AMOUNT_ASC')
		},
		sortTotalOrderDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_ORDER_AMOUNT_DESC')
		},
		openCreateModal: function () {
			window.Bus.$emit('open-create-customer')
		},
		sortCustomer: function (sortValue) {
			this.loading = true
			let page = this.$services.endpoints.COMPANY_ENDCUSTOMERS + `?limit=${this.limit}&ordering=${sortValue}`
			this.GET_CUSTOMER_FILTER.map(item => {
				page += `&${item.mode}=${item.value}`
			})
			if (this.filter !== '') {
				page += `&search=${this.filter}`
			}
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table td {
	padding: 0.1rem 0.55rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-select {
	border: 1px solid #DFE0EB !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}


/* Animated loading bar */
@keyframes progress {
	from {
	  transform: translateX(-100%);
	}
	to {
	  transform: translateX(100%);
	}
  }
  .animate-progress {
	animation: progress 2s linear infinite;
  }
  
</style>
